import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';

function OffcanvasNavbar() {
  return (
    <>
      {['sm'].map((expand) => (
        <Navbar key={expand} expand={expand} className="navbar-bg mb-0">
          <Container fluid>
            <Navbar.Brand href="/home" className="text-light fs-3">Eric</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="custom-toggle" />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="custom-offcanvas"
            >
              <Offcanvas.Header closeButton className="navbar-bg text-light fs-3">
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Tinghan
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-5 navbar-bg">
                  <NavLink to="/home" className="nav-link text-light fs-4">Home</NavLink>
                  <NavLink to="/education" className="nav-link text-light fs-4">Education</NavLink>
                  <NavDropdown
                    title="Experience"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                    className="custom-dropdown fs-4"
                  >
                    <NavDropdown.Item as={NavLink} to="/blockchain">Blockchain and Web 3</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} to="/cyber">Cyber Security</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} to="/hobbies">Hobbies</NavDropdown.Item>
                  </NavDropdown>
                  <NavLink to="/contact" className="nav-link text-light fs-4">Contact Me</NavLink>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasNavbar;
