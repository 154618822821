import React, { useEffect } from 'react';
import '../App.css';
import Accordion from 'react-bootstrap/Accordion';
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import oscp from '../img/logos/oscp.png';
import jypra from '../img/logos/jypra.png';
import intern from '../img/Jypra.JPG';
import uni from '../img/uni.jpg';
import video from '../video/cyber_intro.mp4';
import { Helmet } from 'react-helmet';

function Cyber() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
      }, []);


  return (
    <div className="Cyber">
        <Helmet>
        <title>Eric Portfolio - Cyber</title>
      </Helmet>
      <div className="text-box">
        <h1>Cyber Security</h1>
        <video  
        controls autoPlay  
        src={video} 
        type="video/mp4"
        className='cyber-video'>
        Your browser does not support the video tag.
        </video>

        <h2>University Study</h2><br/><br/>
        <Accordion defaultActiveKey="1" data-aos="fade-left" className="custom-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Container>
                <Row>
                <img src={uni} alt="UNI" className="uni-image" data-aos="fade-left" />
                </Row>
              </Container>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={4} >
                  <strong>Key Words:</strong>
                  <ul>
                    <li>Nmap</li>
                    <li>Wireshark</li>
                    <li>Kali Linux</li>
                    <li>Encryption</li>
                    <li>Network Security</li>
                    <li>Incident Response</li>
                  </ul>
                </Col>
                <Col md={8} className="text-end">
                  <p >
                    During my university studies, I took a variety of cyber security-related courses that
                    provided a strong foundation in the field. These courses included Vulnerability Assessment
                    and Penetration Testing, Cyber Security Governance, Policy, Ethics & Law, Fundamentals of
                    Cyber Security, Information Security Essentials, and more. I am proud to have achieved a GPA 
                    of 6 or above in all these courses, reflecting my dedication and proficiency in the subject matter.
                  </p>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <section className="jypra-intern" data-aos="fade-up">
          <h2>JYPRA Group Internship</h2>
          <Container>
          <Col className="d-flex align-items-start">
            <img src={jypra} alt="JYPRA" className="jypra-logo" />
            <img src={intern} alt="JYPRAINTTERN" className="jypra-logo" />
            </Col>
          </Container>
          <p className="section-description" data-aos="fade-up"><br/>
          <strong>Mar 2023 – Present<br/><br/></strong>
            During my internship at JYPRA Group, I played a key role in deploying, configuring, and monitoring 
            Sentinel One XDR solutions. I conducted comprehensive vulnerability assessments using Qualys and 
            led several blockchain security projects focused on identifying and mitigating crypto scams and auditing 
            smart contracts. This hands-on experience has significantly enhanced my practical skills in the field.
          </p>
         
        </section>


        <section className="current-progress" data-aos="fade-up">
          <h2>Current Progress</h2> <br/>
          <p className="section-description" data-aos="fade-up">
            I am currently self-studying the Penetration Testing with Kali Linux (PWK/PEN-200) course, 
            with the goal of earning the Offensive Security Certified Professional (OSCP) certification 
            this year. This challenging course is further honing my skills in penetration testing and 
            advanced cyber security techniques.
          </p>
          <img src={oscp} alt="OSCP" className="oscp-image" />
        </section>
      </div>
    </div>
  );
}

export default Cyber;
