import React, { useEffect } from 'react';
import '../App.css';
import Accordion from 'react-bootstrap/Accordion';
import { Container, Row, Col, Table } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import graduate from '../img/graduate.jpg'; 
import inno from '../img/inoss.jpg'; 
import nutri from '../img/nutrition.jpg';
import uni from '../img/uq.jpg';  
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet';


import sentinelOneLogo from '../img/logos/sentinel-one.png';
import cLogo from '../img/logos/c.png';
import qualysLogo from '../img/logos/qualys.png';
import sqlLogo from '../img/logos/sql.png';
import wiresharkLogo from '../img/logos/wireshark.png';
import dockerLogo from '../img/logos/docker.png';
import nmapLogo from '../img/logos/nmap.png';
import sparkLogo from '../img/logos/spark.png';
import pythonLogo from '../img/logos/python.png';
import solidityLogo from '../img/logos/solidity.png';
import reactLogo from '../img/logos/react.png';
import awsLogo from '../img/logos/aws.png';


function Education() {
    useEffect(() => {
      AOS.init({ duration: 2000 });
    }, []);
  
    const itemVariants = {
      hidden: { opacity: 0, x: -20 },
      visible: { opacity: 1, x: 0 }
    };
  
    const techStack = [
        { name: 'Sentinel One', logo: sentinelOneLogo },
        { name: 'C Programing', logo: cLogo },
        { name: 'Qualys', logo: qualysLogo },
        { name: 'Mysql', logo: sqlLogo },
        { name: 'Wireshark', logo: wiresharkLogo },
        { name: 'Docker', logo: dockerLogo },
        { name: 'Nmap', logo: nmapLogo },
        { name: 'Apache Spark', logo: sparkLogo },
        { name: 'Python', logo: pythonLogo },
        { name: 'Solidity', logo: solidityLogo },
        { name: 'React', logo: reactLogo },
        { name: 'AWS', logo: awsLogo }
    ];

    const rows = [];
    for (let i = 0; i < techStack.length; i += 3) {
        rows.push(techStack.slice(i, i + 3));
    }

  return (
    <div className="Education">
      <Helmet>
        <title>Eric Portfolio - Education</title>
      </Helmet>
      <div className="text-box">
        <h1>Education</h1>
        <div className="education-master" data-aos="fade-up">
          <h2>Master of Cyber Security</h2>
          <h4>University of Queensland</h4>
          <p>July 2022 - July 2023</p>
          <p>Overall GPA: 6/7</p>
        </div>
        
        <Accordion defaultActiveKey="0"
            data-aos="fade-right" className="custom-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Container>
                <Row>
                  <Col xs={12} md={6}>
                    <img 
                      src={graduate} 
                      alt="graduate" 
                      className="graduate-image" 
                        // Apply AOS animation
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <img 
                      src={inno} 
                      alt="graduate" 
                      className="graduate-image" 
                      data-aos="fade-right"  // Apply AOS animation
                    />
                  </Col>
                </Row>
              </Container>
            </Accordion.Header>
            <Accordion.Body>
              During the journey of my Master's in Cyber Security at the University of Queensland, 
              I gained profound knowledge in various aspects of cyber security, including cryptography, 
              network security, and ethical hacking. This experience not only enhanced my technical skills 
              but also provided me with a strong foundation in understanding and mitigating modern-day 
              cyber threats. My dedication to the field is reflected in my high GPA of 6/7, and my 
              involvement in innovative projects and research during my time at the university.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="education-bachelor" data-aos="fade-up">
          <h2>Bachelor of Health Science</h2>
          <h4>University of Queensland</h4>
          <p>Feb 2018- Nov 2021</p>
        </div>
        <Accordion defaultActiveKey="1"
            data-aos="fade-left"
            className="custom-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Container>
                <Row>
                <Col xs={12} md={6}>
                    <img 
                      src={uni} 
                      alt="uq" 
                      className="graduate-image" 
                      data-aos="fade-left"  
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <img 
                      src={nutri} 
                      alt="nutrition" 
                      className="graduate-image" 
                    />
                  </Col>
                </Row>
              </Container>
            </Accordion.Header>
            <Accordion.Body>
            My Bachelor of Health Science provided a strong foundation in data privacy, 
            ethical considerations, and regulatory compliance, which are critical in cybersecurity. 
            The skills I developed in safeguarding sensitive health data directly translate to 
            protecting digital assets in cybersecurity roles. Additionally, my understanding of 
            secure data management has been invaluable in applying blockchain technology to create 
            transparent and secure systems, especially in healthcare-related blockchain applications.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* Animated Tech Stack Section */}
        <div className="tech-stack" data-aos="fade-up">
          <Table className="tech-stack-table">
            <thead>
              <tr>
                <th colSpan="3" className="tech-stack-title">Tech Stack</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <motion.tr
                  key={rowIndex}
                  initial="hidden"
                  animate="visible"
                  variants={itemVariants}
                  transition={{ delay: rowIndex * 1.5, duration: 1.5 }} 
                >
                  {row.map((tech, index) => (
                    <motion.td
                      key={index}
                      initial="hidden"
                      animate="visible"
                      variants={itemVariants}
                      transition={{ delay: (rowIndex * 1.5) + (index * 0.5), duration: 1.5 }}
                    >
                      <img src={tech.logo} alt={tech.name} className="tech-logo" />
                      <span>{tech.name}</span>
                    </motion.td>
                  ))}
                </motion.tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default Education;
