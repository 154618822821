import React from 'react';
import '../App.css';
import { Button, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <div className="Contact">
        <Helmet>
        <title>Eric Portfolio - Contact</title>
      </Helmet>
      <div className="text-box">
        <h1>Contact Me</h1>
        <p>If you want to get in touch, you can reach me at:</p>
        <p>Email: ericabne@gmail.com</p>
        <p>Phone: +61 432276840</p>
        <p>Or find me on social media:</p>
        <Container className="social-buttons-container">
          <Button href="https://www.linkedin.com/in/tinghan-wang-69ba64224/" variant="primary" className="social-button">
            LinkedIn
          </Button>
          <Button href="https://github.com/MIaomiao-Eric" variant="dark" className="social-button">
            GitHub
          </Button>
          <Button href="https://www.facebook.com/profile.php?id=100015899109598" variant="primary" className="social-button">
            Facebook
          </Button>
        </Container>
      </div>
    </div>
  );
}

export default Contact;
