import React, { useEffect } from 'react';
import Typed from 'typed.js';
import '../App.css';
import AOS from 'aos';
import Button from 'react-bootstrap/Button';
import myself from '../img/myself.jpg'; 
import scuba from '../img/su.jpg'; 
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from 'react-helmet';

function Home() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
    const typed = new Typed('.auto-input', {
      strings: [
        "Hi! I'm Tinghan(Eric) Wang", 
        "I'm a Cyber Security Specialist",
        "also a Blockchain developer!",
        "I love talking about security, blockchain", 
        "&& scuba diving"
      ],
      typeSpeed: 75,
      backSpeed: 40,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="Home">
        <Helmet>
        <title>Eric Portfolio - Home</title>
      </Helmet>
        <div className="text-box">
            <h1><span className="auto-input"></span></h1>
            <p>Feel free to reach out if you need assistance 
                with blockchain development or cybersecurity solutions for your business.</p>
            <div className="button-container">
                <Button href="/cyber" variant="primary">CYBER</Button>
                <Button href="/blockchain" variant="secondary">WEB 3</Button>
            </div>
            <img src={myself} alt="Myself" className="myself-image" data-aos="fade-up" />    
        </div>

        <div className="centered-accordion">
            <Accordion defaultActiveKey="1" data-aos="fade-up" className="custom-accordion">
                <Accordion.Item eventKey="0" className="custom-accordion-item">
                <Accordion.Header className="custom-accordion-header">
                    <img 
                    src={scuba} 
                    alt="Scuba Diving" 
                    className="accordion-header-img" 
                    data-aos="fade-up" 
                    />
                </Accordion.Header>
                <Accordion.Body className="custom-accordion-body">
                    <h5 className="accordion-body-title">Beyond the Screen</h5>
                    <p className="accordion-body-text">
                    When I'm not immersed in code, I find joy in exploring the world,
                    whether it's through travel or diving deep into the underwater wonders.
                    </p>
                    <Button variant="primary" href="/hobbies" className="accordion-body-button">More</Button>
                </Accordion.Body>
                </Accordion.Item>
            </Accordion>      
        </div>
        
    </div>

  );
}

export default Home;
