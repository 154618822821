import React from 'react';
import '../App.css';
import bg from "../img/hobbies/bg.MOV";
import bg_i from "../img/hobbies/bg.jpg"; // Import the background image for mobile devices

import musgrave from "../img/hobbies/Lady_Musgrave.MP4";

import bridge from "../img/hobbies/bridge.jpg";
import casino from "../img/hobbies/casino.jpg";
import bcd from "../img/hobbies/cbd.jpg";
import eric_wang from "../img/hobbies/eric_wang.jpg";
import horse from "../img/hobbies/horse.jpg";
import tigger from "../img/hobbies/tigger.jpg";
import tree from "../img/hobbies/tree.jpg";
import unknow from "../img/hobbies/unknow.jpg";
import uq from "../img/hobbies/uq.jpg";
import { Container, Carousel } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

function Hobbies() {
  return (
    <div className="Hobbies">
        <Helmet>
        <title>Eric Portfolio - Hobbies</title>
      </Helmet>
      <div className="background-container">
        <video autoPlay muted loop className="background-video">
          <source src={bg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <img src={bg_i} alt="Background" className="background-image" />
      </div>
      <div className="hb-text-box">
        <h1>My Hobbies</h1>
        <p>Outside of work, I’m a scuba diving instructor and a passionate photographer:</p>
        <ul>
          <li>I’m a professional scuba diving instructor with five years of teaching experience. Being underwater is my favorite way to relax and refresh my mind. 
            I also love challenging myself, so I’ve delved into the world of technical diving through TDI, where I’ve started doubles manifold and sidemount dives to explore even more of the underwater world.</li>
          <li>Alongside diving, I have a deep love for photography, especially capturing moments with drones and underwater shots.</li>
        </ul>

        <video controls className="hobbies-video">
          <source src={musgrave} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <Container className="carousel-container mt-4">
          <Carousel>
            <Carousel.Item>
              <img src={eric_wang} alt="Eric Wang" className="d-block w-100 hobbies-carousel-image" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={horse} alt="Horse" className="d-block w-100 hobbies-carousel-image" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={tigger} alt="Tigger" className="d-block w-100 hobbies-carousel-image" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={tree} alt="Tree" className="d-block w-100 hobbies-carousel-image" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={unknow} alt="Unknown" className="d-block w-100 hobbies-carousel-image" />
            </Carousel.Item>
          </Carousel>
        </Container>

        <Container className="carousel-container mt-4">
          <Carousel>
            <Carousel.Item>
              <img src={bridge} alt="Bridge" className="d-block w-100 hobbies-carousel-image" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={casino} alt="Casino" className="d-block w-100 hobbies-carousel-image" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={bcd} alt="CBD" className="d-block w-100 hobbies-carousel-image" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={uq} alt="UQ" className="d-block w-100 hobbies-carousel-image" />
            </Carousel.Item>
          </Carousel>
        </Container>
      </div>
    </div>
  );
}

export default Hobbies;
