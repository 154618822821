import React, { useEffect } from 'react';
import '../App.css';
import Accordion from 'react-bootstrap/Accordion';
import { Container, Row, Col, Carousel} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import inno from '../img/inno.jpg';
import price from '../img/inovation_price.jpg';
import sdlt from '../video/sdlt.mp4';
import certification from '../img/IS2023 Certificate_twang_page-0001.jpg';
import { Helmet } from 'react-helmet';


import binanceA from '../img/binanceA.jpg';
import binanceB from '../img/binanceB.jpg';
import binanceC from '../img/binanceC.jpg';
import ace from '../img/ace.jpg';
import algo from '../img/algo.jpg';
import industry from '../img/industry.jpg';
import workshop from '../img/workshop.jpg';
import sdltt from '../img/sdltt.jpg';
import sdltp from '../img/sdltp.jpg';


function Blockchain() {
  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = 'https://public.bnbstatic.com/unpkg/growth-widget/cryptoCurrencyWidget@0.0.13.min.js';
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup: remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="Blockchain">
      <div
        className="binance-widget-marquee"
        data-cmc-ids="1,1027,1839,5426,3408,74,5805,3890,7083"
        data-theme="dark"
        data-transparent="false"
        data-powered-by="Powered by"
        data-disclaimer="Disclaimer"
      >
      </div>

      <Helmet>
        <title>Eric Portfolio - Blockchain</title>
      </Helmet>

      <div className="text-box">
        <h1>Blockchain Development</h1>

        
        <video 
        controls autoPlay  
        src={sdlt} 
        type="video/mp4"
        className='bc-video'>
        Your browser does not support the video tag.
        </video>

        <section className="favorite-part">
          <h2>Aha, Finally My Favorite Part!</h2><br/>
          <p>
            As a tech lover, I'm constantly learning about blockchain and dreaming of making great contributions to this field one day.
          </p>
        </section>


        <section className="capstone-project" data-aos="fade-right">
        <Accordion defaultActiveKey="0" data-aos="fade-right" className="custom-accordion">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                <Container>
                    <Row>
                    <Col xs={12} md={5} className="custom-margin-col">
                        <img 
                        src={inno} 
                        alt="innovation" 
                        className="inno-image" 
                        style={{ width: '100%', height: 'auto'}} 
                        data-aos="fade-right"  
                        />
                    </Col>
                    <Col xs={12} md={4} className="custom-margin-col">
                        <Row>
                        <Col xs={12}>
                            <img 
                            src={price} 
                            alt="price" 
                            className="inno-image mb-3" 
                            style={{ width: '100%', height: 'auto' }} 
                            data-aos="fade-right"  
                            />
                        </Col>
                        <Col xs={12}>
                            <img 
                            src={certification} 
                            alt="certification" 
                            className="inno-image" 
                            style={{ width: '100%', height: 'auto' }} 
                            data-aos="fade-right"  
                            />
                        </Col>
                        </Row>
                    </Col>
                    </Row>
                </Container>
                </Accordion.Header>
                <Accordion.Body>
                As a blockchain enthusiast, I never miss a chance to work on hands-on projects. 
                During my master's studies, I collaborated with Dr. Naipeng Dong on my capstone project titled 
                <em>"Blockchain in the Aviation Training Industry."</em> I'm thrilled to share that I won the <strong>Best Distributed Ledger Technology Project </strong> 
                 at the 2023 EECS Innovation Showcase! :)
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        </section>

        <section className="uq-blockchain-club" data-aos="fade-up">
          <h3>UQ Blockchain Club</h3>
          <p  className="section-description" data-aos="fade-up">
            As a promoter of blockchain society, I established the UQ Blockchain Club, where I serve as president. We've built strong collaborations with industry partners like Binance, Algorand, ATB, and others. Over the past year, we've hosted many wonderful events, and we're committed to continuing this momentum to bring more people into the blockchain space!
          </p>

          <Container  className="carousel-container">
            <Carousel fade className="custom-carousel">
              <Carousel.Item>
                <img
                  src={ace}
                  alt="ACESIP"
                  className="d-block w-100"
                />
                <Carousel.Caption>
                  <h3>ACE_SIP Summer School</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={algo}
                  alt="Algorithm Workshop"
                  className="d-block w-100"
                />
                <Carousel.Caption>
                  <h3>Algorand Career Event</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={industry}
                  alt="Industry Conference"
                  className="d-block w-100"
                />
                <Carousel.Caption>
                  <h3>Industry Workshops</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={workshop}
                  alt="Blockchain Workshop"
                  className="d-block w-100"
                />
                <Carousel.Caption>
                  <h3>Algorand Pyteal Workshop</h3>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>

            <Carousel className="custom-carousel mt-4"> 
              <Carousel.Item>
                <img
                  src={binanceA}
                  alt="Binance Event A"
                  className="d-block w-100"
                />
                <Carousel.Caption>
                  <h3>Binance Career Development Event</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={binanceB}
                  alt="Binance Event B"
                  className="d-block w-100"
                />
                <Carousel.Caption>
                  <h3>Binance Career Development Event</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={binanceC}
                  alt="Binance Event C"
                  className="d-block w-100"
                />
                <Carousel.Caption>
                  <h3>Binance Career Development Event</h3>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Container> 
        </section>

        <section className="more-section">
            <h3 className="section-title" data-aos="fade-up">More to Come!</h3>
            <p className="section-description" data-aos="fade-up" data-aos-delay="100">
                To further my research in blockchain, I am currently working at the University of Queensland as a research assistant, focusing on blockchain-related projects. I am eager to engage with all blockchain communities, and I love connecting with the geniuses and experts in this field. In November 2023, I had the honor of being invited as a local chair and web chair for the 7th SDLT Conference at UQ.
            </p>
            <div className="image-grid" data-aos="fade-up" data-aos-delay="200">
                <div className="image-item" data-aos="zoom-in" data-aos-delay="300">
                <img src={sdltt} alt="SDLT1" className="conference-image" />
                </div>
                <div className="image-item" data-aos="zoom-in" data-aos-delay="500">
                <img src={sdltp} alt="SDLT3" className="conference-image" />
                </div>
            </div>
        </section>
      </div>
    </div>
  );
}

export default Blockchain;
