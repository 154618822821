import React from 'react';
import Button from 'react-bootstrap/Button';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OffcanvasNavbar from './components/Navbar';
import Home from './pages/home';
import Education from './pages/education';
import CyberSecurity from './pages/cyber';
import Blockchain from './pages/blockchain';
import Hobbies from './pages/hobbies';
import Contact from './pages/contact';

function App() {
  return (
    <Router>
      <OffcanvasNavbar />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/education" element={<Education />} />
        <Route path="/cyber" element={<CyberSecurity />} />
        <Route path="/blockchain" element={<Blockchain />} />
        <Route path="/hobbies" element={<Hobbies />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <div className="sticky-button-container">
        <Button 
          as="a" 
          className="custom-button" 
          href="/cv/tinghan_wang_cv.pdf" 
          variant="primary"
          download
        >
          Download CV
        </Button>
        <Button as="a" className="custom-button" href="/contact" variant="primary">
          Contact Me
        </Button>
      </div>
    </Router>
  );
}

export default App;
